import React from "react";
import PropTypes from "prop-types";
import NewsletterCheckbox from "../../../components/checkbox/NewsletterCheckbox";

// PropTypes
Newsletter.propTypes = {
  errors: PropTypes.object,
  register: PropTypes.func.isRequired,
};

export default function Newsletter({ register }) {
 
  return (
      <NewsletterCheckbox register={register} />
  );
}
