import React from "react";
import PropTypes from "prop-types";

import CheckboxBox from "./CheckboxBox2";
import ControlledCheckbox from "./ControlledCheckbox";

export default function NewsletterCheckbox({ register }) {
  NewsletterCheckbox.propTypes = {
    register: PropTypes.func.isRequired,
  };

  var newsletterCheckbox = (
    <>
      <ControlledCheckbox
        register={register}
        registerWhat={"newsletter"}
        checkboxLabel={"Sign up to HomeOwners Alliance FREE newsletter for latest advice, services and money saving offers"}
      />
    </>
  );

  return (
    <>
      <CheckboxBox
        checkboxes={newsletterCheckbox}
        label={"Sign up to HomeOwners Alliance newsletter for latest advice, services and money saving offers"}
        checkboxErrorText={null} // Provide a default value for checkboxErrorText
      />
    </>
  );
}
